<template>
<v-card>
  <v-card-title>Inserted products</v-card-title>
  <v-data-table
    :headers="headers"
    :items="inserted"
    dense>
  </v-data-table>
  <!-- Grouped example -->
  <!-- <v-data-table :headers="updatedHeaders" :items="updated" hide-default-footer item-key="field" group-by="sku">
    <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
            <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                <v-icon v-if="!isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
            {{ group }}
        </td>
    </template>
  </v-data-table> -->
</v-card>
</template>

<script>

export default {
  props: {
    inserted: Array
  },
  data () {
    return {
      headers: [
        { text: 'Product', value: 'sku', width: '10%' },
        { text: 'Title', value: 'title', width: '60%' },
        { text: 'Artist', value: 'categoryDescription', width: '15%' },
        { text: 'Category', value: 'category', width: '15%' }
      ]
    }
  }
}
</script>
